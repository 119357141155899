<template>
  <div class="thing_category_add">
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            商品属性设置
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form ref="categoryForm" :rules="Rules" label-width="120px" style="padding-left:40px">
        <el-row class="left tile" style="margin-bottom: 20px;">
          <el-col :span="3">图片</el-col>
          <el-col :span="5">属性</el-col>
          <el-col :span="3">原价</el-col>
          <el-col :span="3">售价</el-col>
          <el-col :span="3">库存</el-col>
          <el-col :span="7">加减库存</el-col>
        </el-row>
        <el-row class="left data-list" v-for="(item,index) in list" :key="index">
          <el-col :span="3">
            <div class="previewUpload">
              <div v-show="item.made_thumb" class="previewItem">
                <img :src="item.made_thumb" alt="">
                <i class="el-icon-close delIcon" @click="delThumb(index)"></i>
              </div>
              <div class="previewItems" @click="openUploadDialog(1,'thumb',index)" v-if="!item.made_thumb"><i class="el-icon-plus previewadd"></i></div>
            </div>
            <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
          </el-col>
          <el-col :span="5" class="attr-value">
            {{item.made_str}}
          </el-col>
          <el-col :span="3">
            <el-input v-model="item.original_price" type="number" placeholder="请输入原价" class="price"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input v-model="item.shop_price" type="number" placeholder="请输入售价" class="price"></el-input>
          </el-col>
          <el-col :span="3">
            <el-input v-model="item.quantity" type="number" :disabled="true" placeholder="请输入库存" class="price"></el-input>
          </el-col>
          <el-col :span="7">
            <el-input v-model="item.goods_number" placeholder="请输入库存" v-if="item.status" style="width: 150px; margin-left: 10px;"></el-input>

            <el-button type="primary" plain @click="ruku(index)" v-if="item.status=='jian'" style="margin-left: 10px;">确定删减</el-button>
            <el-button type="primary" plain @click="ruku(index)" v-if="item.status=='jia'" style="margin-left: 10px;">确定入库</el-button>
            <el-button type="danger" plain @click="setStatus(index,'')" v-if="item.status" style="margin-left: 10px;">返回</el-button>

            <el-button type="danger" plain @click="setStatus(index,'jian')" v-if="!item.status" style="margin-left: 10px;">-</el-button>
            <el-button type="primary" plain @click="setStatus(index,'jia')" v-if="!item.status" style="margin-left: 10px;">+</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" @click="subAdd">提交</el-button>
      <el-button type="default" @click="back">返回</el-button>
    </el-card>
  </div>
</template>

<script>
import Upload from '@/components/Upload/Upload.vue'
export default {
  components:{
    Upload
  },
  data () {
    return {
      Rules:{},
      //上传图片
      uploadVisible:false,
      pictureType:'',
      chooseImgCount:0,
      
      list: [],

      index: -1,
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			if(this.$route.query.goods_id){
				this.getList();
			}
		}
  },
  methods: {
  	getList(){
  		var url = 'thing/goods_made';
			let params = {
				goods_id: this.$route.query.goods_id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					this.list = res.list;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
  	},
    back(){
      this.$router.go(-1)
    },
    //上传图片
    openUploadDialog(count,pictureType,index){
      this.index = index;
      this.pictureType = pictureType;
      this.chooseImgCount = count;
      this.uploadVisible = true;
    },
    closeDelDialog(){
      this.uploadVisible=false;
    },
    receiveRes(chooseList, pictureType){
      console.log(chooseList);
      if(pictureType==='thumb'){
        this.list[this.index].made_thumb = chooseList[0].img_path;
      }
      this.uploadVisible=false;
    },
    delThumb(index){
      this.list[index].made_thumb = '';
    },
    setStatus(index,v) {
      this.list[index].status = v;
    },
    //提交
    subAdd(){
      console.log(this.list)
      var params = {
        list: JSON.stringify(this.list)
      }
      console.log(params)
      this.fd_post('thing/goods_made_dosetting', params).then((res) => {
    		if(res.status){
    			this.$refs.categoryForm.resetFields();
    			this.$message.success("操作成功");
    			this.$router.push({path: '/thing_goods'});
    		}else{
    			this.$message.error(res.msg);
    		}
      }).catch((err) => {
          this.$message.error('网络错误');
      });
    },
    ruku(index) {
      // console.log(this.list[index].goods_number);
      // return
      var url = 'thing/ruku';
      if (this.goods_number<=0) {
        this.$message.error("请输入大于0的数字");return;
      }
      var params = {
        goods_id: this.list[index].goods_id,
        made_id: this.list[index].made_id,
        fuhao: this.list[index].status,
        goods_number: this.list[index].goods_number
      };
      console.log(params);
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          if (this.list[index].status=='jian') {
            this.list[index].quantity = Number(this.list[index].quantity)-Number(this.list[index].goods_number);
          } else {
            this.list[index].quantity = Number(this.list[index].quantity)+Number(this.list[index].goods_number);
          }
          this.list[index].status = '';
          this.$message.success('操作成功');
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .thing_category_add{
    .left{
      text-align: left;
    }
    .price{
      width: 95%;
    }
    .tile{
      .el-col{
        padding-left: 5px;
        color: #666;
      }
    }
    .data-list{
      /*line-height: 78px;*/
      margin-bottom: 10px;
      .el-col{
        display: flex;
        height: 78px;
        align-items: center;
      }
      .attr-value{
        font-size: 12px;
      }
    }
    
    .previewUpload{
      display: flex;
      flex-wrap: wrap;
      .previewItems{
        width: 78px;
        height: 78px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        /*margin:0 10px 10px 0;*/
        cursor: pointer;
        position: relative;
        .previewadd {
          font-size: 28px;
          color: #8c939d;
          width: 78px;
          height: 78px;
          line-height: 78px;
          text-align: center;
        }
        &:hover{
          border-color: #409EFF;
        }
      }
      .previewItem{
        width: 78px;
        height: 78px;
        border-radius: 6px;
        /*margin:0 10px 10px 0;*/
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        .delIcon{
          display: none;
        }
        &:hover{
          .delIcon{
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 30px;
          }
        }
        
        img{
          width: 100%;
          height: 100%;
        }
        
      }
    }
  }
</style>